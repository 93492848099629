import React from "react";
// CSS helpers
import styled from "styled-components";

// Components

// Atoms
import { Title } from "components/atoms/title";
import { Button } from "components/atoms/button";

// Images
import MindMap1 from "assets/images/mindmap/mindmap-1.jpg";

// Contract
import abi from "assets/contracts/SOG.json";

import { ethers } from "ethers";
import { useState, useEffect } from "react";

interface HorizontalRowProps {
  mt?: number;
}

const HorizontalRow = styled.hr<HorizontalRowProps>`
  margin-right: 80px;
  margin-left: 80px;
  background: ${({ theme }) => theme.colors.gray["400"]};
  height: 1px;
  border-width: 0;
  margin-top: ${({ mt }) => mt ?? 0}px;
`;

const ContentContainer = styled.div`
  height: 320px;
  margin-right: 80px;
  margin-top: 80px;
  margin-left: 80px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
`;

const TextContent = styled.div`
  margin-left: 200px;
  // background: ${({ theme }) => theme.colors.gray["400"]};
  width: 100%;
  line-height: 200%;
`;

const CtaContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 200%;
  color: var(--text-color);
  width: 800px;
`;

const Container = styled.div``;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IframeProps {
  isExperience?: Boolean;
}

const Iframe = styled.iframe<IframeProps>`
  opacity: ${({ isExperience }) => (isExperience === true ? 1 : 0)};
  transition: opacity 500ms ease-in-out;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const HubContainer = () => {
  const [balance, setBalance] = useState<string | undefined>();
  const [currentAccount, setCurrentAccount] = useState<string | undefined>();
  const [chainId, setChainId] = useState<number | undefined>();
  const [chainname, setChainName] = useState<string | undefined>();
  const [isHolder, setHolder] = useState<boolean | undefined>(false);
  const [isExperience, setExperience] = useState<boolean | undefined>(false);
  const [accessRequested, setAccessRequested] = useState<boolean | undefined>(
    false
  );

  const contractAddress: string = "0x2a4425631aB27186796a3a2808b0be2Ad35edFf2";

  useEffect(() => {
    // Exit if account already connected
    if (!currentAccount || !ethers.utils.isAddress(currentAccount)) return;
    // Exit if no metamask extension
    if (!window.ethereum) return;

    // Setup provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // Get Balance
    provider.getBalance(currentAccount).then((result) => {
      setBalance(ethers.utils.formatEther(result));
    });

    provider.getNetwork().then((result) => {
      setChainId(result.chainId);
      setChainName(result.name);
    });
  }, [currentAccount]);

  const onClickConnect = async () => {
    //client side code
    if (!window.ethereum) {
      console.log("please install MetaMask");
      return;
    }

    //we can do it using ethers.js
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // MetaMask requires requesting permission to connect users accounts
    provider
      .send("eth_requestAccounts", [])
      .then((accounts) => {
        if (accounts.length > 0) {
          setCurrentAccount(accounts[0]);
        }
      })
      .catch((e) => console.log(e));

    // console.log({ currentAccount, chainId, balance, chainname });
  };

  const accessExperience = async () => {
    //we can do it using ethers.js
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();

    const contract = new ethers.Contract(contractAddress, abi);

    console.log(currentAccount);

    const numberOfTokens = await contract
      .connect(signer)
      .balanceOf(currentAccount);

    setAccessRequested(true);

    const launchExperience = () => {
      console.log("launch exp");

      setExperience(true);
    };

    if (numberOfTokens.toNumber() === 0) {
      setHolder(false);
    } else {
      setHolder(true);
      setTimeout(launchExperience, 4000);
    }
  };

  return (
    <Container>
      <Title
        type="h1"
        fontSize="100"
        mobilefontsize="3O"
        mt={80}
        align="center"
      >
        ENTER THE OC
      </Title>

      <HorizontalRow />
      <ContentContainer>
        <img src={MindMap1} alt="Societhy Hub Image" />
        <TextContent>
          We are creating those “experiential” NFTs as access to the experiences
          within the Societhy ecosystem. They will be Containerided in 5 themes
          : Fashion, Music, Sport, Art & Lifestyle. Holding a Fashion Tiny will
          provide hodler get an airdrop of tickets for all fashion events on
          Societhy.
        </TextContent>
      </ContentContainer>
      <HorizontalRow mt={80} />

      {!chainId && (
        <Footer>
          <CtaContainer>
            <Button type="primary" onClick={onClickConnect}>
              CONNECT METAMASK
            </Button>
          </CtaContainer>

          <CtaContainer>
            <Button type="secondary" href="hub">
              OTHER WALLETS
            </Button>
          </CtaContainer>
        </Footer>
      )}

      {/* If connected to Main Net Network */}
      {!accessRequested && chainId === 1 && (
        <Footer>
          <CtaContainer>
            You are now connected with the following address : {currentAccount}
          </CtaContainer>

          <CtaContainer>
            <Button type="secondary" onClick={accessExperience}>
              ACCESS EXPERIENCE
            </Button>
          </CtaContainer>
        </Footer>
      )}

      {/* If connected to another network */}
      {!accessRequested && chainId && chainId !== 1 && (
        <Footer>
          <CtaContainer>
            You're actually on {chainname} network. First choose Ethereum
            Mainnet !
          </CtaContainer>
        </Footer>
      )}

      {isHolder && (
        <Footer>
          <CtaContainer>
            The Opening Ceremony is launching soon (waiting time ~3min)
          </CtaContainer>
        </Footer>
      )}
      {isHolder && (
        <Iframe
          src="https://portal.furioos.com/embed/9YsQeYW7QFQ32SxqT?autoRun=true"
          title="The Openning Ceremony"
          frameBorder={0}
          allowFullScreen
          isExperience={isExperience}
        ></Iframe>
      )}
      {accessRequested && !isHolder && (
        <Footer>
          <CtaContainer>
            You don't have an OG Pass on this wallet, check that you're on the
            right wallet, here's your address : {currentAccount}
          </CtaContainer>
        </Footer>
      )}
    </Container>
  );
};
